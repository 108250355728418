import Image from "@/components/Image";
import Link from "next/link";
import type { Payload } from "@local/payload-client/src/types";
import { BunnyLoader } from "@local/utils/src/bunnyLoader";
interface Props {
  data: Payload.Category;
  logo?: Payload.Media;
}
export const CategoryItem = ({
  data,
  logo
}: Props) => {
  const image = data.image?.url ? data.image : logo;
  return <li className="inline-flex flex-col w-64 w-full max-w-full text-center lg:w-auto" data-sentry-component="CategoryItem" data-sentry-source-file="Item.tsx">
      <div className="relative w-full group">
        <div className="relative w-full overflow-hidden bg-gray-100 rounded-lg aspect-square">
          <Image alt={`Image for ${data?.name} category`} className="object-fill object-center group-hover:opacity-75" height={image?.height} src={image?.url as string} width={image?.width as number} loader={process.env.NEXT_PUBLIC_BUNNY_URL ? BunnyLoader : undefined} data-sentry-element="Image" data-sentry-source-file="Item.tsx" />
        </div>
        <div className="mt-6">
          <h3 className="mt-1 font-semibold text-zinc-900">
            <Link href={`/category/${data?.slug}`} prefetch={false} data-sentry-element="Link" data-sentry-source-file="Item.tsx">
              <span className="absolute inset-0" />
              {data?.name}
            </Link>
          </h3>
        </div>
      </div>
    </li>;
};