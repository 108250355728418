import type { Payload } from "@local/payload-client/src/types";
import { ContentBlockServer } from "./ContentBlock";
import ButtonServer, { ButtonIntent, ButtonStretch } from "./ui/button";
import { CategoryItem } from "./Categories/Item";
import { fetchMedia } from "@local/payload-client/src/media";
interface Props {
  block: Payload.CategoriesListingBlock;
}
const SingleCategory = async ({
  data
}: {
  data: Payload.Category;
}) => {
  //data.image is kinda crazy
  const req = await fetchMedia({
    id: data.image as unknown as string
  });
  return <ul className="mb-6" data-sentry-component="SingleCategory" data-sentry-source-file="CategoriesListingBlock.tsx">
      <CategoryItem key={`home-categorie-listing-${data.id}`} data={{
      ...data,
      image: req as Payload.Media
    }} logo={{
      url: "./notfound.svg",
      width: 1200,
      height: 1200
    } as Payload.Media} data-sentry-element="CategoryItem" data-sentry-source-file="CategoriesListingBlock.tsx" />
    </ul>;
};
export const CategoriesListingBlockServer = ({
  block
}: Props) => {
  return <ContentBlockServer className="flex flex-col justify-center px-6 py-10 mb-4 text-center lg:py-6" data-sentry-element="ContentBlockServer" data-sentry-component="CategoriesListingBlockServer" data-sentry-source-file="CategoriesListingBlock.tsx">
      <h2 className="text-2xl font-bold lg:text-4xl">
        {block.headline ?? "Shop by Category"}
      </h2>

      <div className="grid grid-cols-2 gap-4 mt-12 mb-6 md:grid-cols-4">
        {block.categories?.map(category => {
        return <SingleCategory key={`category-${(category as Payload.Category).id}`} data={category as Payload.Category} />;
      })}
      </div>

      <ButtonServer content={block.ctaText ?? "View All Categories"} intent={ButtonIntent.CTA} stretch={ButtonStretch.fixed} url="/categories" className="mx-auto" data-sentry-element="ButtonServer" data-sentry-source-file="CategoriesListingBlock.tsx" />
    </ContentBlockServer>;
};
export default CategoriesListingBlockServer;